import domReady from '@roots/sage/client/dom-ready';
import Chart from 'chart.js/auto';

/**
 * Application entrypoint
 */

domReady(async () => {

  $('.signet-container').addClass('js');

  const animatioContainer = $('.animation-container');

  for (let index = 0; index < 49; index++) {

    const delay = Math.floor(Math.random() * 100 * (2.5 - 0.1 + 1) + 0.1) / 100;

    const element = $('<div class="dot dot-'+ index +'" style="animation-delay: '+ delay +'s"></div>');

    animatioContainer.append(element);

  }

  $('.load-more').each(function (index, element) {
    var loadMoreButton = $(element);
    var container = loadMoreButton.parent().parent().find('.loadmorecontainer');
    var loadMoreData = loadMoreButton.data('loadoptions');
    var loading = false;
    var defaultText = loadMoreButton.text();
    loadMoreData.page = 1;

    loadMoreButton.on('click', function(e) {
      e.preventDefault();

      if(loading) return;

      var data = {
        action: 'loadmore',
        loadmore: loadMoreData,
      };

      loading = true;
      loadMoreButton.text(wpbackend.loadingString);
      jQuery.post(wpbackend.ajaxurl, data, function(response) {

        console.log('RET', response);

        container.append(response);

        loadMoreData.page++;

        if(loadMoreData.page >= loadMoreData.max ) {
          loadMoreButton.remove();
        }
        loading = false;
        loadMoreButton.text(defaultText);
      });
    })
  })


  if(window.charts) {
    window.charts.forEach((chartInitFunction => {
      chartInitFunction(Chart);
    }))
  }

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
